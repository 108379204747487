<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="panel has-bg">
        <h3>{{ $t('drilling.headlineBearingDimension') }}</h3>
        <div class="row">
          <!-- Lagerbeite -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingWidth"
            >
              <template slot="label">
                {{ $t('bearings.bearingWidth') }} <span class="text-muted">a</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingWidth"
                  v-model="bearingWidth"
                  @input="checkPositions"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerlänge -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingLength"
            >
              <template slot="label">
                {{ $t('bearings.bearingLength') }} <span class="text-muted">b</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingLength"
                  v-model="bearingLength"
                  @input="checkPositions"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('drilling.headlineHoles') }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <!-- Durchmesser Bohrungen -->
            <b-form-group
              label-for="holesDiameter"
            >
              <template slot="label">
                {{ $t('drilling.holesDiameter') }} <span class="text-muted">d</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="holesDiameter"
                  v-model="holesDiameter"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <!-- Anzahl Bohrungen -->
            <b-form-group
              label-for="holesCount"
            >
              <template slot="label">
                {{ $t('drilling.holesCount') }} <span class="text-muted">n</span>
              </template>
              <b-input-group>
                <b-select
                  id="holesCount"
                  v-model="holesCount"
                  :options="holesCountOptions"
                  @change="checkPositions"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <!--
        <div v-if="holesCount > 0">
          <p>{{ $t('bearings.holesLocation') }}</p>
          <div class="row">
            <div class="col-lg-3">
              <b-form-group
                label-for="holesY1"
              >
                <template slot="label">
                  <span class="text-muted">y1</span>
                </template>
                <b-input-group append="mm">
                  <FormInputInteger
                    id="holesY1"
                    v-model="holesY1"
                    type="text"
                    @input="checkPositionY1"
                  />
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group
                label-for="holesY2"
              >
                <template slot="label">
                  <span class="text-muted">y2</span>
                </template>
                <b-input-group append="mm">
                  <FormInputInteger
                    id="holesY2"
                    v-model="holesY2"
                    type="text"
                    @input="checkPositionY2"
                  />
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group
                label-for="holesX1"
              >
                <template slot="label">
                  <span class="text-muted">x1</span>
                </template>
                <b-input-group append="mm">
                  <FormInputInteger
                    id="holesX1"
                    v-model="holesX1"
                    type="text"
                    @input="checkPositionX1"
                  />
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-lg-3">
              <b-form-group
                label-for="holesX3"
              >
                <template slot="label">
                  <span class="text-muted">x3</span>
                </template>
                <b-input-group append="mm">
                  <FormInputInteger
                    id="holesX3"
                    v-model="holesX3"
                    @input="checkPositionX3"
                  />
                </b-input-group>
              </b-form-group>
            </div>
            <div
              v-if="holesCount == 2"
              class="col-lg-3"
            >
              <b-form-group
                label-for="holesX2"
              >
                <template slot="label">
                  <span class="text-muted">x2</span>
                </template>
                <b-input-group append="mm">
                  <FormInputInteger
                    id="holesX2"
                    v-model="holesX2"
                    @input="checkPositionX2"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
    <div
      v-if="holesCount > 0"
      class="col-lg-6"
    >
      <h3>
        {{ $t('bearings.headlineKeyFigures') }}
        <b-button
          id="popover-key-figures"
          variant="link"
        >
          <font-awesome-icon icon="question-circle" />
        </b-button>
        <b-popover
          target="popover-key-figures"
          triggers="hover"
          placement="top"
          container="body"
        >
          <p>{{ $t('drilling.locationHolesTooltip') }}</p>
          <p v-html="$t('general.tooltipAdditionalInfo')"></p>
        </b-popover>
      </h3>
      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
            <tr :class="minAreaStatus">
              <td>
                A<sub class="text-muted">Bohrung</sub> = {{ areaHoles }} mm<sup>2</sup>
              </td>
              <td>&le;</td>
              <td class="text-right">
                0,1 x A<sub class="text-muted">Brutto</sub>
                = {{ minArea | numeralFormat('0.0') }} mm<sup>2</sup>
              </td>
            </tr>
            <!--
            <tr :class="minY1Status">
              <td>
                Y<sub class="text-muted">1</sub> = {{ holesY1 }} mm
              </td>
              <td>&ge;</td>
              <td class="text-right">
                0,3 x a = {{ minY1 | numeralFormat('0.0') }} mm
              </td>
            </tr>
            <tr :class="minY2Status">
              <td>
                Y<sub class="text-muted">2</sub> = {{ holesY2 }} mm
              </td>
              <td>&ge;</td>
              <td class="text-right">
                0,3 x a = {{ minY2 | numeralFormat('0.0') }} mm
              </td>
            </tr>
            <tr :class="minX1Status">
              <td>
                X<sub class="text-muted">1</sub> = {{ holesX1 }} mm
              </td>
              <td>&ge;</td>
              <td class="text-right">
                0,3 x a = {{ minX1 | numeralFormat('0.0') }} mm
              </td>
            </tr>
            <tr
              v-if="holesCount == 2"
              :class="minX2Status"
            >
              <td>
                X<sub class="text-muted">2</sub> = {{ holesX2 }} mm
              </td>
              <td>&ge;</td>
              <td class="text-right">
                2 x d = {{ minX2 | numeralFormat('0.0') }} mm
              </td>
            </tr>
            <tr :class="minX3Status">
              <td>
                X<sub class="text-muted">3</sub> = {{ holesX3 }} mm
              </td>
              <td>&ge;</td>
              <td class="text-right">
                0,3 x a = {{ minX3 | numeralFormat('0.0') }} mm
              </td>
            </tr>
            -->
          </tbody>
        </table>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('drilling.headlineIllustration') }}</h3>
        <div v-if="holesCount == 1">
          <img
            src="../../assets/img/abbildungen/bohrungen-1.png"
            alt=""
          />
        </div>
        <div v-if="holesCount == 2">
          <img
            src="../../assets/img/abbildungen/bohrungen-2.png"
            alt=""
          />
        </div>
        <div v-if="holesCount == 3">
          <img
            src="../../assets/img/abbildungen/bohrungen-3.png"
            alt=""
          />
        </div>
        <div v-if="holesCount == 4">
          <img
            src="../../assets/img/abbildungen/bohrungen-4.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputInteger from '../app/form/FormInputInteger.vue';
import DisabledInputs from '../../mixins/DisabledInputs';
import OutputHelper from '../../helpers/OutputHelper';
import CalcHelper from '../../helpers/CalcHelper';
import CalcToolsDrillingHelper from '../../helpers/CalcToolsDrillingHelper';

export default {
  components: {
    FormInputInteger,
  },
  mixins: [DisabledInputs],
  props: {
    propBearingWidth: {
      type: Number,
      default: 280,
    },
    propBearingLength: {
      type: Number,
      default: 400,
    },
    propHolesCount: {
      type: Number,
      default: 2,
    },
    propHolesDiameter: {
      type: Number,
      default: 40,
    },
    propHolesY1: {
      type: Number,
      default: 140,
    },
    propHolesY2: {
      type: Number,
      default: 140,
    },
    propHolesX1: {
      type: Number,
      default: 133,
    },
    propHolesX2: {
      type: Number,
      default: 133,
    },
    propHolesX3: {
      type: Number,
      default: 133,
    },
  },
  data() {
    return {
      bearingThicknessOptions: this.$dataOptionsHelper.getBearingThicknessOptions(),
      holesCountOptions: this.$dataOptionsHelper.getBearingHolesOptions(),
      bearingWidth: this.propBearingWidth,
      bearingLength: this.propBearingLength,
      holesDiameter: this.propHolesDiameter,
      holesCount: this.propHolesCount,
      holesY1: this.propHolesY1,
      holesY2: this.propHolesY1,
      holesX1: this.propHolesX1,
      holesX2: this.propHolesX2,
      holesX3: this.propHolesX3,
    };
  },
  computed: {
    minArea() {
      const areaBrutto = CalcHelper.getAreaBrutto(
        this.bearingWidth,
        this.bearingLength,
      );
      return 0.1 * areaBrutto;
    },
    minY1() {
      return 0.3 * OutputHelper.convertToInteger(this.bearingWidth);
    },
    minY2() {
      return 0.3 * OutputHelper.convertToInteger(this.bearingWidth);
    },
    minX1() {
      return 0.3 * OutputHelper.convertToInteger(this.bearingWidth);
    },
    minX2() {
      return 2 * OutputHelper.convertToInteger(this.holesDiameter);
    },
    minX3() {
      return 0.3 * OutputHelper.convertToInteger(this.bearingWidth);
    },
    areaHoles() {
      return CalcHelper.getAreaHoles(
        this.holesCount,
        this.holesDiameter,
      );
    },
    minAreaStatus() {
      return {
        'table-success text-success': this.areaHoles <= this.minArea,
        'table-danger text-danger': this.areaHoles > this.minArea,
      };
    },
    minY1Status() {
      return {
        'table-success text-success': OutputHelper.convertToInteger(this.holesY1) >= this.minY1,
        'table-danger text-danger': OutputHelper.convertToInteger(this.holesY1) < this.minY1,
      };
    },
    minY2Status() {
      return {
        'table-success text-success': OutputHelper.convertToInteger(this.holesY2) >= this.minY2,
        'table-danger text-danger': OutputHelper.convertToInteger(this.holesY2) < this.minY2,
      };
    },
    minX1Status() {
      return {
        'table-success text-success': OutputHelper.convertToInteger(this.holesX1) >= this.minX1,
        'table-danger text-danger': OutputHelper.convertToInteger(this.holesX1) < this.minX1,
      };
    },
    minX2Status() {
      return {
        'table-success text-success': OutputHelper.convertToInteger(this.holesX2) >= this.minX2,
        'table-danger text-danger': OutputHelper.convertToInteger(this.holesX2) < this.minX2,
      };
    },
    minX3Status() {
      return {
        'table-success text-success': OutputHelper.convertToInteger(this.holesX3) >= this.minX3,
        'table-danger text-danger': OutputHelper.convertToInteger(this.holesX3) < this.minX3,
      };
    },
  },
  methods: {
    checkPositionY1() {
      const pos = CalcToolsDrillingHelper.getHolesPositionY1Y2(
        'y1',
        this.holesY1,
        this.holesY2,
        this.bearingWidth,
      );
      this.holesY1 = pos.y1;
      this.holesY2 = pos.y2;
    },
    checkPositionY2() {
      const pos = CalcToolsDrillingHelper.getHolesPositionY1Y2(
        'y2',
        this.holesY1,
        this.holesY2,
        this.bearingWidth,
      );
      this.holesY1 = pos.y1;
      this.holesY2 = pos.y2;
    },
    checkPositionX1() {
      const pos = CalcToolsDrillingHelper.getHolesPositionX1X2X3(
        'x1',
        this.holesX1,
        this.holesX2,
        this.holesX3,
        this.bearingLength,
        this.holesCount,
      );
      this.holesX1 = pos.x1;
      this.holesX2 = pos.x2;
      this.holesX3 = pos.x3;
    },
    checkPositionX3() {
      const pos = CalcToolsDrillingHelper.getHolesPositionX1X2X3(
        'x3',
        this.holesX1,
        this.holesX2,
        this.holesX3,
        this.bearingLength,
        this.holesCount,
      );
      this.holesX1 = pos.x1;
      this.holesX2 = pos.x2;
      this.holesX3 = pos.x3;
    },
    checkPositionX2() {
      const pos = CalcToolsDrillingHelper.getHolesPositionX1X2X3(
        'x2',
        this.holesX1,
        this.holesX2,
        this.holesX3,
        this.bearingLength,
        this.holesCount,
      );
      this.holesX1 = pos.x1;
      this.holesX2 = pos.x2;
      this.holesX3 = pos.x3;
    },
    checkPositions() {
      const positionY1Y2 = CalcToolsDrillingHelper.getHolesPositionY1Y2(
        'newCalc',
        this.holesY1,
        this.holesY2,
        this.bearingWidth,
      );
      this.holesY1 = positionY1Y2.y1;
      this.holesY2 = positionY1Y2.y2;
      const pos = CalcToolsDrillingHelper.getHolesPositionX1X2X3(
        'newCalc',
        this.holesX1,
        this.holesX2,
        this.holesX3,
        this.bearingLength,
        this.holesCount,
      );
      this.holesX1 = pos.x1;
      this.holesX2 = pos.x2;
      this.holesX3 = pos.x3;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
