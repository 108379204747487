const DisabledInputs = {
  props: {
    disableInputs: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.disableInputs.forEach((item) => {
      const el = this.$el.querySelectorAll(`#${item}`)[0];
      if (typeof el !== 'undefined') {
        el.setAttribute('disabled', 'disabled');
      }
    });
  },
};
export default DisabledInputs;
