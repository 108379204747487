<template>
  <ToolsFormDrilling />
</template>

<script>
import ToolsFormDrilling from '../../components/tools/ToolsFormDrilling.vue';

export default {
  components: {
    ToolsFormDrilling,
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped lang="scss">

</style>
