<template>
  <input
    :id="id"
    type="text"
    :class="`form-control ${additionalClass}`"
    :value="value"
    @input="debounceInput"
  />
</template>

<script>
import AutoNumeric from 'autonumeric';
import debounce from 'lodash.debounce';

export default {
  name: 'FormInputInteger',
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    additionalClass() {
      return `form-control-integer-${this.$outputHelper.generateUniqueId()}`;
    },
  },
  mounted() {
    const defaultOptions = {
      decimalPlaces: 0,
      minimumValue: 0,
      digitGroupSeparator: '',
      watchExternalChanges: true,
    };

    new AutoNumeric(`.${this.additionalClass}`, { ...defaultOptions, ...this.options });
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceInput: debounce(function (e) {
      this.$emit('input', e.target.value);
    }, 500),
  },
};
</script>
