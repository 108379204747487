import OutputHelper from './OutputHelper';

export default {
  // Berechne L1 und L2 neu, entweder werden die Werte durch Eingabe eines
  // einzelnen Wertes neu berechnet oder durch das Ändern der Breite a
  getHolesPositionY1Y2(editedValueType, y1, y2, a) {
    const y1Casted = OutputHelper.convertToInteger(y1);
    const y2Casted = OutputHelper.convertToInteger(y2);
    const aCasted = OutputHelper.convertToInteger(a);
    const pos = {
      y1: y1Casted,
      y2: y2Casted,
    };
    if (editedValueType === 'y1') {
      if (y1Casted < aCasted) {
        pos.y2 = aCasted - y1Casted;
      } else {
        pos.y1 = aCasted;
        pos.y2 = 0;
      }
    } else if (editedValueType === 'y2') {
      if (y2Casted < aCasted) {
        pos.y1 = aCasted - y2Casted;
      } else {
        pos.y2 = aCasted;
        pos.y1 = 0;
      }
      pos.y1 = aCasted - y2Casted;
    } else if (editedValueType === 'newCalc') {
      // neu berechnen wenn Länge, Breite oder Anzahl der Löcher geändert wurde
      pos.y1 = OutputHelper.convertToInteger(aCasted / 2);
      pos.y2 = OutputHelper.convertToInteger(aCasted / 2);
    }
    return pos;
  },
  // Berechne x1,x3 und x2 neu, entweder werden die Werte durch Eingabe eines
  // einzelnen Wertes neu berechnet oder durch das Ändern der Länge b
  // oder durch das Ändern der Anzahl der Bohrungen
  getHolesPositionX1X2X3(editedValueType, x1, x2, x3, b, countHoles) {
    const x1Casted = OutputHelper.convertToInteger(x1);
    const x3Casted = OutputHelper.convertToInteger(x3);
    const x2Casted = OutputHelper.convertToInteger(x2);
    const bCasted = OutputHelper.convertToInteger(b);
    const countHolesCasted = OutputHelper.convertToInteger(countHoles);
    const pos = {
      x1: x1Casted,
      x3: x3Casted,
      x2: x2Casted,
    };
    if (editedValueType === 'x1') {
      if (countHolesCasted === 1) {
        if (x1Casted < bCasted) {
          pos.x3 = bCasted - x1Casted;
        } else {
          pos.x1 = bCasted;
          pos.x3 = 0;
        }
      } else if (countHolesCasted === 2) {
        if (x1Casted < bCasted) {
          // Berechne übrig bleibende Fläche für x3 und x2
          // Wenn größer als 0, dann bleibende Fläche x2 zuweisen.
          // Sonst x2 auf 0 setzen und den Rest von x3 abziehen
          const remainingArea = bCasted - (x1Casted + x3Casted);
          if (remainingArea >= 0) {
            pos.x2 = OutputHelper.convertToInteger(remainingArea);
          } else {
            pos.x3 = x3Casted + remainingArea;
            pos.x2 = 0;
          }
        } else {
          pos.x1 = bCasted;
          pos.x3 = 0;
          pos.x2 = 0;
        }
      }
    } else if (editedValueType === 'x3') {
      if (countHolesCasted === 1) {
        if (x3Casted < bCasted) {
          pos.x1 = bCasted - x3Casted;
        } else {
          pos.x1 = 0;
          pos.x3 = bCasted;
        }
      } else if (countHolesCasted === 2) {
        if (x3Casted < bCasted) {
          // Berechne übrig bleibende Fläche für x1 und x2
          // Wenn größer als 0, dann bleibende Fläche x2 zuweisen.
          // Sonst x2 auf 0 setzen und den Rest von x1 abziehen
          const remainingArea = bCasted - (x1Casted + x3Casted);
          if (remainingArea >= 0) {
            pos.x2 = OutputHelper.convertToInteger(remainingArea);
          } else {
            pos.x1 = x1Casted + remainingArea;
            pos.x2 = 0;
          }
        } else {
          pos.x1 = 0;
          pos.x3 = bCasted;
          pos.x2 = 0;
        }
      }
    } else if (editedValueType === 'x2') {
      if (x2Casted < bCasted) {
        const remainingArea = bCasted - x2;
        pos.x1 = OutputHelper.convertToInteger(remainingArea / 2);
        pos.x3 = OutputHelper.convertToInteger(remainingArea / 2);
      } else {
        pos.x1 = 0;
        pos.x3 = 0;
        pos.x2 = bCasted;
      }
    } else if (editedValueType === 'newCalc') {
      // neu berechnen wenn Länge, Breite oder Anzahl der Löcher geändert wurde
      if (countHolesCasted === 0) {
        pos.x1 = 0;
        pos.x3 = 0;
        pos.x2 = 0;
      } else if (countHolesCasted === 1) {
        pos.x1 = OutputHelper.convertToInteger(bCasted / 2);
        pos.x3 = OutputHelper.convertToInteger(bCasted / 2);
        pos.x2 = 0;
      } else if (countHolesCasted === 2) {
        pos.x1 = OutputHelper.convertToInteger(bCasted / 3);
        pos.x3 = OutputHelper.convertToInteger(bCasted / 3);
        pos.x2 = OutputHelper.convertToInteger(bCasted / 3);
      }
    }
    return pos;
  },
};
